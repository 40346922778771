import {
    LOGIN_SUCCESS,
    LOGOUT,
  } from "../actions/types";
  
  const initialState = { 
    isLoggedIn: false, 
    userProfile: {},
    token: ""
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          userProfile: payload.userProfile,
          token: payload.token
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          userProfile: {},
          token: ""
        };
      default:
        return state;
    }
  }