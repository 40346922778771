import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from "./reducers";
const middleware = [thunk];

const persistConfig = {
  key: 'timly-hire',
  transforms: [
    encryptTransform({
      secretKey: '4226452948404D635166546A576D5A7134743777217A25432A462D4A614E6452',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

const persistor = persistStore(store)

export {store, persistor}